<template>
  <v-container fluid class="pa-0">
    <v-card>
      <v-app-bar dark color="secondary">
        <v-btn icon @click="$router.push('/retailers')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="isEditing"
          icon
          @click="deleteItem"
          class="mr-2"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          color="success"
          @click="saveItem"
          :loading="saving"
        >
          <v-icon left>mdi-content-save</v-icon>
          Save
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-form ref="form" v-model="isFormValid" @submit.prevent="saveItem">
          <v-tabs v-model="activeTab" background-color="transparent" grow>
            <v-tab>Basic Information</v-tab>
            <v-tab>Contact & Address</v-tab>
            <v-tab>App Settings</v-tab>
            <v-tab>Kiosk Settings</v-tab>
            <v-tab>Global Settings</v-tab>
          </v-tabs>

          <v-tabs-items v-model="activeTab">
            <!-- Basic Information Tab - Updated with UID -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model.trim="currentItem.name"
                        label="Name"
                        :rules="[v => !!v || 'Name is required']"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model.trim="currentItem.uid"
                        label="UID"
                        :rules="[v => !!v || 'UID is required']"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model.trim="currentItem.role"
                        label="Role"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="currentItem.licenses"
                        label="Licenses"
                        type="number"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model.trim="currentItem.notes"
                        label="Notes"
                        outlined
                        auto-grow
                        rows="3"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- Contact & Address Tab -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="currentItem.email"
                        label="Email"
                        type="email"
                        @blur="emailToLowerCase"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="currentItem.phone"
                        label="Phone Number"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="currentItem.website"
                        label="Website"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentItem.address"
                        label="Address"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="currentItem.mailAddress"
                        label="Mail Address"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="currentItem.mailCity"
                        label="City"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="currentItem.mailState"
                        label="State"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="currentItem.mailCountry"
                        label="Country"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="currentItem.mailPostal"
                        label="Postal Code"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- App Settings Tab -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-autocomplete
                    v-model="currentItem.removedApps"
                    :items="apps"
                    item-text="metadata.name"
                    item-value="metadata.packageName"
                    label="Excluded Apps"
                    outlined
                    chips
                    multiple
                    deletable-chips
                    :loading="loading"
                  ></v-autocomplete>

                  <v-autocomplete
                    v-model="currentItem.activityBlocklist"
                    :items="activities"
                    item-text="description"
                    item-value="_id"
                    label="Blocked Activities"
                    outlined
                    chips
                    multiple
                    deletable-chips
                    :loading="loading"
                  ></v-autocomplete>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- Kiosk Settings Tab -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentItem.kioskProfile.appDwnUrl"
                        label="App Download URL"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentItem.kioskProfile.bootanimDwnUrl"
                        label="Boot Animation Download URL"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- Global Settings Tab -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-switch
                    v-model="currentItem.globalSettings.disallowVideoPlayback"
                    label="Disallow Video Playback"
                    color="primary"
                  ></v-switch>
                  <v-switch
                    v-model="currentItem.globalSettings.autoEntitlement"
                    label="Auto Entitlement"
                    color="primary"
                  ></v-switch>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="3000"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RetailerDetail',
  
  data() {
    return {
      isFormValid: true,
      loading: true,
      saving: false,
      activeTab: 0,
      apps: [],
      activities: [],
      currentItem: {
        timestamp: null,
        version: 1,
        name: '',
        uid: '',
        address: '',
        licenses: 0,
        email: '',
        mailAddress: '',
        mailCity: '',
        mailState: '',
        mailCountry: '',
        mailPostal: '',
        notes: '',
        phone: '',
        website: '',
        role: '',
        removedApps: [],
        activityBlocklist: [],
        kioskProfile: {
          appDwnUrl: '',
          bootanimDwnUrl: '',
          bootanimation: ''
        },
        globalSettings: {
          disallowVideoPlayback: false,
          autoEntitlement: false
        }
      },
      snackbar: {
        show: false,
        text: '',
        color: ''
      }
    };
  },
  
  computed: {
    formTitle() {
      return this.isEditing ? 'Edit Retailer' : 'New Retailer';
    },
    isEditing() {
      return this.$route.params.retailer !== 'new';
    }
  },
  
  methods: {
    showSnackbar(text, color = 'success') {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },

    emailToLowerCase() {
      if (this.currentItem.email) {
        this.currentItem.email = this.currentItem.email.toLowerCase();
      }
    },

    async fetchRetailer(id) {
      try {
        const response = await axios.get(`https://api.safetelecom.net/api/retailers/${id}`);
        this.currentItem = {
          ...this.currentItem,
          ...response.data,
          globalSettings: {
            ...this.currentItem.globalSettings,
            ...(response.data.globalSettings || {})
          },
          kioskProfile: {
            ...this.currentItem.kioskProfile,
            ...(response.data.kioskProfile || {})
          }
        };
      } catch (error) {
        console.error('Error fetching retailer:', error);
        this.showSnackbar('Failed to fetch retailer details', 'error');
      }
    },

    async fetchApps() {
      try {
        const response = await axios.get('https://api.safetelecom.net/api/apps');
        this.apps = response.data.sort((a, b) => 
          a.metadata.name.localeCompare(b.metadata.name)
        );
      } catch (error) {
        console.error('Error fetching apps:', error);
        this.showSnackbar('Failed to fetch apps list', 'error');
      }
    },

    async fetchActivities() {
      try {
        const response = await axios.get('https://api.safetelecom.net/api/activities');
        this.activities = response.data.sort();
      } catch (error) {
        console.error('Error fetching activities:', error);
        this.showSnackbar('Failed to fetch activities list', 'error');
      } finally {
        this.loading = false;
      }
    },

    async saveItem() {
      if (!this.$refs.form.validate()) return;

      this.saving = true;
      try {
        if (this.isEditing) {
          await axios.put(`https://api.safetelecom.net/api/retailers/${this.currentItem._id}`, this.currentItem);
        } else {
          await axios.post('https://api.safetelecom.net/api/retailers', this.currentItem);
        }
        this.showSnackbar('Retailer saved successfully');
        this.$router.push('/retailers');
      } catch (error) {
        console.error('Error saving retailer:', error);
        this.showSnackbar('Failed to save retailer', 'error');
      } finally {
        this.saving = false;
      }
    },

    async deleteItem() {
      if (!confirm('Are you sure you want to delete this item?')) return;

      try {
        await axios.delete(`https://api.safetelecom.net/api/retailers/${this.currentItem._id}`);
        this.showSnackbar('Retailer deleted successfully');
        this.$router.push('/retailers');
      } catch (error) {
        console.error('Error deleting retailer:', error);
        this.showSnackbar('Failed to delete retailer', 'error');
      }
    }
  },
  
  async created() {
    if (this.isEditing) {
      await this.fetchRetailer(this.$route.params.retailer);
    }
    await Promise.all([
      this.fetchApps(),
      this.fetchActivities()
    ]);
  }
};
</script>