<template>
  <div>
    <v-app-bar dark color="secondary">
      <v-toolbar-title class="white--text">Apps</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn rounded @click="refreshPage">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn rounded class="ml-2" :to="{ name: 'App', params: { id: 'new' } }">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-dialog
          v-model="dialog"
          fullscreen
        >
          <v-card>
            <v-app-bar fixed dark color="primary">
              <v-btn icon dark @click="close">
                <v-icon>close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text>Save</v-btn>
              </v-toolbar-items>
            </v-app-bar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" >
                    <img v-if="currentItem.metadata.icon" :src="`//storage.googleapis.com/kosheros-store/icons/${currentItem.metadata.icon}`" style="max-width: 150px">
                    <img v-else src="//via.placeholder.com/150x150" style="max-width: 150px">
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field
                      v-model="currentItem.metadata.icon"
                      label="Icon Source"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field
                      v-model="currentItem.metadata.name"
                      label="Name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field
                      v-model="currentItem.metadata.authorName"
                      label="Author Name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field
                      v-model="currentItem.metadata.authorEmail"
                      label="Author Email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field
                      v-model="currentItem.metadata.webSite"
                      label="Website"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" >
                    <vue-editor v-model="currentItem.metadata.description" :editorToolbar="editorToolbar"></vue-editor>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" >
                    <v-textarea
                      rows="1"
                      auto-grow
                      v-model="currentItem.metadata.summary"
                      label="Summary"
                    ></v-textarea>
                  </v-col>
                </v-row>
                  <!-- <v-flex xs12>
                    <v-row justify="center">
                      <v-date-picker reactive v-model="appDate"></v-date-picker>
                    </v-row>
                  </v-flex> -->
                <v-row>
                  <v-col cols="12" sm="6" md="4" >
                    <v-combobox
                      :items="categories"
                      label="Categories"
                      v-model="currentItem.metadata.categories"
                      multiple
                      chips
                      clearable
                    ></v-combobox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
      </v-dialog>
    </v-app-bar>
    <template>
      <v-card>
			<v-card-text style="overflow-x: auto">
				<v-data-table
        dense
					:mobile-breakpoint="mobileBreakpoint"
					:loading="loading"
					:headers="headers"
					:items="items"
					:footer-props="{
						'items-per-page-options': [25, 50, 100, { 'text': 'All', 'value': -1 }]
					}"
					:sort-desc="[true]"
					:items-per-page="25"
          :search="nameSearch"
					item-key="_id"
					must-sort
					class="elevation-1"
				>
          <template v-slot:top>
            <v-text-field
              v-model="nameSearch"
              label="Search"
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn text color="primary" class="mr-2" :to="{ name: 'App', params: { id: item._id } }">edit</v-btn>
            <v-btn text color="red" class="mr-2" @click="deleteItem(item)">delete</v-btn>
          </template>
          <template v-slot:[`item.metadata.icon`]="{ item }">
            <img v-if="item.metadata.icon" :src="`https://storage.googleapis.com/kosheros-store/icons/${item.metadata.icon}`" style="max-width: 8em; vertical-align: middle;">
            <img v-else src="//via.placeholder.com/150" style="max-width: 8em; vertical-align: middle;">
          </template>
					<template v-slot:[`item.metadata.packageName`]="{ item }">
            <a :href="`//api.safetelecom.net/api/appcheck/stats/${item.metadata.packageName}`" target="_blank">{{ item.metadata.packageName }}</a>
          </template>
					<template v-slot:[`item.metadata.lastUpdated`]="{ item }">
            {{ moment(item.metadata.lastUpdated).format('MMMM DD, YYYY') }}
          </template>
				</v-data-table>
			</v-card-text>
		</v-card>
    </template>
    <v-dialog v-model="deleteDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Confirm Deletion</v-card-title>
        <v-card-text>
          Are you sure you want to delete this app? This action is irreversible.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="confirmDelete">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      timeout="3000"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios"
import moment from 'moment'

export default {
  name: "AppsList",
  data() {
    return {
      loading: true,
      dialog: false,
			moment: moment,
      items: [],
      mobileBreakpoint: 0,
      nameSearch: "",
      editedIndex: -1,
      editorToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      headers: [
        { text: 'Icon', value: 'metadata.icon' },
        { text: 'Name', value: 'metadata.name' },
        { text: 'Package Name', value: 'metadata.packageName' },
        { text: 'Version', value: 'package.versionName' },
        { text: 'Min SDK', value: 'package.minSdkVersion' },
        { text: 'Categories', value: 'metadata.categories' },
        { text: 'Added', value: 'metadata.lastUpdated' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      categories: [
        "Banking",
        "Business",
        "Cloud Storage",
        "connectivity",
        "Email",
        "Jewish",
        "Money",
        "Music",
        "Navigation",
        "Printing",
        "Productivity",
        "Shopping",
        "Sports",
        "Torah",
        "Travel",
        "Weather",
      ],
      currentItem: {
        metadata: {
          icon: "",
          name: "",
          authorName: "",
          authorEmail: "",
          webSite: "",
          description: "",
          summary: "",
          categories: []
        }
      },
      newItem: {
        metadata: {
          icon: "",
          name: "",
          authorName: "",
          authorEmail: "",
          webSite: "",
          description: "",
          summary: "",
          categories: []
        }
      },
      deleteDialog: false,
      itemToDelete: null,
      snackbar: false,
      snackbarText: '',
      snackbarColor: ''
    }
  },
  methods: {
    getItems() {
      this.loading = true;
      axios.get(`https://api.safetelecom.net/api/apps`).then((response) => {
        this.items = response.data.sort((a, b) => {
          if (a.metadata.name < b.metadata.name) { return -1 }
          if (a.metadata.name > b.metadata.name) { return 1 }
          return 0;
        });
        this.loading = false
      });
    },
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.currentItem = Object.assign({}, item)
      this.dialog = true
    },
    saveItem() {
    this.currentItem.package.added = new Date(this.appDate).getTime();
    axios
      .put(
        `https://api.safetelecom.net/api/apps/${this.currentItem._id}`,
        this.currentItem
      )
      .then(() => {
        this.$router.push("/apps");
      });
    },
    deleteItem(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },

    async confirmDelete() {
      if (!this.itemToDelete) return;

      try {
        await axios.delete(`https://api.safetelecom.net/api/apps/${this.itemToDelete._id}`);
        this.showSnackbar('App deleted successfully!', 'success');
        this.refreshPage(); // Call the refresh method
      } catch (error) {
        console.error('Error deleting app:', error);
        this.showSnackbar('Failed to delete app. Please try again.', 'error');
      } finally {
        this.deleteDialog = false;
        this.itemToDelete = null;
      }
    },

    showSnackbar(text, color) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },
    refreshPage() {
      this.items = [];
      this.getItems();
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.currentItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New App' : 'Edit App'
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  created() {
    this.getItems();
  },
};
</script>
