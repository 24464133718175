<template>
  <div>
    <v-app-bar dark color="secondary">
      <v-toolbar-title class="white--text">Banned Apps</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn rounded icon dark @click="refreshPage">
        <v-icon>refresh</v-icon>
      </v-btn>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              rounded
              icon dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Add App</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="currentItem.name"
                      label="App Name"
                    ></v-text-field>
                  </v-col>
                 </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="saveItem"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-app-bar>
    <v-container>
      <v-card>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="items"
          :sort-by="['name']"
          :sort-desc="[false]"
          :items-per-page="-1"
          item-key="_id"
          must-sorts
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              small
              rounded 
              icon 
              class="mr-2" 
              :to="{ name: 'Activity', params: { id: item._id } }">
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn
              small
              rounded
              icon
              class="mr-2"
              @click="deleteItem(item._id)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>


<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: true,
      dialog: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      currentItem: {
        name: ''
      }
    };
  },
	props: [],
  methods: {
    initData() {
      this.loading = true;
      axios.get(`https://api.safetelecom.net/api/bannedapps/`)
      .then((response) => {
        this.items = response.data;
        this.loading = false;
      });
    },
    saveItem() {
      axios
        .post(`https://api.safetelecom.net/api/bannedapps/`, this.currentItem)
        .then(() => {
          this.refreshPage();
        });
    },
    deleteItem(id) {
      confirm("Are you sure you want to delete this item?") &&
        axios
          .delete(`https://api.safetelecom.net/api/bannedapps/${id}`)
          .then(() => {
            this.refreshPage();
          });
    },
    close() {
      this.dialog = false
    },
    refreshPage() {
      this.items = [];
      this.initData();
    },
  },
  created() {
    this.initData();
  },
};
</script>
