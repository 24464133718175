<template>
  <div>
    <v-app-bar dark color="secondary">
      <v-toolbar-title class="white--text">Activity Groups</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn rounded icon dark @click="refreshPage">
        <v-icon>refresh</v-icon>
      </v-btn>
      <v-btn rounded icon dark to="/activitygroups/new">
        <v-icon>add</v-icon>
      </v-btn>
    </v-app-bar>
    <v-container fluid>
			<v-card>
				<v-card-title>
					Default BlockList
				</v-card-title>
				<v-card-text>
					<v-layout wrap>
						<v-flex xs12>
							<v-btn
								:color="loading ? 'normal' : 'primary'"
								:loading="loading"
                to="/activitygroups/60dde9659aefe3003abab569"
							>Edit Default Blocklist</v-btn>
						</v-flex>
					</v-layout>
				</v-card-text>
			</v-card>
		</v-container>
    <v-container>
      <v-card>
      <v-card-title>Other BlockLists</v-card-title>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="items"
          :sort-by="['name']"
          :sort-desc="[false]"
          :items-per-page="-1"
          item-key="_id"
          must-sorts
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              small
              rounded 
              icon 
              class="mr-2" 
              :to="{ name: 'ActivityGroup', params: { id: item._id } }">
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn
              small
              rounded
              icon
              class="mr-2"
              @click="deleteItem(item._id)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: true,
      headers: [
        { text: "Activity Group", value: "name" },
        { text: "Description", value: "description" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
    };
  },
	props: [],
  methods: {
    initData() {
      this.loading = true;
      axios.get(`https://api.safetelecom.net/api/activitygroups/`)
      .then((response) => {
        this.items = response.data.filter(item => item._id != "60dde9659aefe3003abab569");
        this.loading = false;
      });
    },
    deleteItem(id) {
      if (id !== "60dde9659aefe3003abab569") {
      confirm("Are you sure you want to delete this item?") &&
        axios
          .delete(`https://api.safetelecom.net/api/activitygroups/${id}`)
          .then(() => {
            this.refreshPage();
          });
      }
      else {
        alert("Default BlockList cannot be deleted.")
      }
    },
    refreshPage() {
      this.items = [];
      this.initData();
    },
  },
  created() {
    this.initData();
  },
};
</script>
