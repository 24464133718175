<template>
  <div>
    <v-toolbar dark color="secondary">
      <v-btn dark icon to="/activities"><v-icon>arrow_back</v-icon></v-btn>
      <v-toolbar-title class="white--text">{{ formTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          dark
          text
          v-if="this.editing"
          @click.native="deleteItem(currentItem._id)"
          ><v-icon>delete</v-icon></v-btn
        >
        <v-btn dark text @click.native="saveItem"><v-icon>save</v-icon></v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card flat style="height: 80vh; overflow-y: scroll">
      <v-container grid-list-xl>
        <v-layout wrap>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.description"
              label="Description"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="currentItem.name"
              label="Activity Name"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ActivityDetail",
  data() {
    return {
      editing: true,
      loading: true,
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Description", value: "description" },
      ],
      items: [],
      currentItem: {
        timestamp: null,
        version: 1,
        name: "",
        description: "",
      },
    };
  },
  computed: {
    formTitle() {
      return !this.editing ? "New Activity" : "Edit Activity";
    },
  },
  methods: {
    getItems() {
      if (!this.editing) {
        this.currentItem = {
          timestamp: null,
          version: 1,
          name: "",
          description: "",
        };
      } else {
        var link =
          "https://api.safetelecom.net/api/activities/" + this.$route.params.id;
        axios.get(link).then((response) => {
          this.currentItem = response.data;
        });
      }
    },
    editItem(id) {
      this.editing = true;
      axios
        .get(`https://api.safetelecom.net/api/activities/${id}`)
        .then((response) => {
          this.currentItem = response.data;
        });
      this.dialog = true;
    },
    saveItem() {
      if (this.$route.params.id === "new") {
        axios
          .post(`https://api.safetelecom.net/api/activities/`, this.currentItem)
          .then(() => {
            this.$router.push("/activities");
          });
      } else {
        axios
          .put(
            `https://api.safetelecom.net/api/activities/${this.currentItem._id}`,
            this.currentItem
          )
          .then(() => {
            this.$router.push("/activities");
          });
      }
    },
    deleteItem(id) {
      confirm("Are you sure you want to delete this item?") &&
        axios
          .delete(`https://api.safetelecom.net/api/activities/${id}`)
          .then(() => {
            this.$router.push("/activities");
          });
    },
  },
  created() {
    if (this.$route.params.id === "new") {
      this.editing = false;
    }
    this.getItems();
  },
};
</script>
