<template>
  <v-container>
    <v-btn @click="goBack" color="primary" class="mb-4">
      <v-icon left>mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <v-card class="mb-4">
      <v-card-title>{{ state === 'add' ? 'Add New App' : 'Update App' }}</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="save">
          <v-file-input
            label="APK Files"
            @change="uploadApk"
            accept=".apk"
            show-size
            truncate-length="15"
            multiple
            :loading="uploading"
            :disabled="uploading"
          ></v-file-input>

          <v-progress-linear
            v-if="uploading"
            :value="uploadProgress"
            color="primary"
            height="25"
          >
            <strong>{{ Math.ceil(uploadProgress) }}%</strong>
          </v-progress-linear>

          <v-alert
            v-if="uploadResult"
            :type="uploadResult.type"
            class="mt-4"
          >
            {{ uploadResult.message }}
          </v-alert>

          <v-chip-group column>
            <v-chip v-for="(split, index) in packageInfo.splits" :key="index" close @click:close="deleteSplit(index)">
              {{ split.apkName }}
            </v-chip>
          </v-chip-group>

          <v-btn
            @click="populateInfo"
            color="primary"
            class="mt-4"
            :disabled="!baseApkProcessed || !packageInfo.packageName"
          >
            Populate Info
          </v-btn>

          <v-row class="mt-4">
            <v-col cols="12" md="6">
              <v-img :src="iconSrc" max-width="150" max-height="150" contain></v-img>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="iconSrc" label="Icon Source"></v-text-field>
            </v-col>
          </v-row>

          <v-text-field v-model="metadata.name" label="App Name" :rules="[rules.required]"></v-text-field>

          <v-row>
            <v-col cols="12" md="4">
              <v-text-field v-model="packageInfo.packageName" label="Package Name" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="packageInfo.versionName" label="Version Name" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="packageInfo.versionCode" label="Version Code" :rules="[rules.required]"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="3">
              <v-text-field v-model="packageInfo.minSdkVersion" label="Minimum SDK Version" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field v-model="packageInfo.targetSdkVersion" label="Target SDK Version" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="packageInfo.nativecode"
                :items="availableABIs"
                label="Native ABIs"
                multiple
                chips
                :rules="[rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-switch v-model="packageInfo.allowedDespiteLimits" label="Limited App?"></v-switch>
            </v-col>
          </v-row>

          <v-text-field v-model="packageInfo.sig" label="Signature" disabled></v-text-field>

          <v-text-field v-model="metadata.authorName" label="Author Name" :rules="[rules.required]"></v-text-field>
          <v-text-field v-model="metadata.authorEmail" label="Author Email" :rules="[rules.required, rules.email]"></v-text-field>

          <v-select
            v-model="metadata.categories"
            :items="categories"
            label="Categories"
            multiple
            chips
          ></v-select>

          <v-switch v-model="skipDate" label="Skip Date Check"></v-switch>
          <v-switch v-model="systemApp" label="System App?"></v-switch>

          <v-text-field v-model="metadata.summary" label="Summary" :rules="[rules.required]"></v-text-field>
          <v-text-field v-model="metadata.webSite" label="Website"></v-text-field>

          <v-card class="mb-4">
            <v-card-title>Description</v-card-title>
            <v-card-text>
              <div class="quill-editor-container">
                <quill-editor
                  v-model="metadata.description"
                  :options="editorOption"
                ></quill-editor>
              </div>
            </v-card-text>
          </v-card>

          <v-text-field
            v-model="packageInfo.added"
            label="Date Added (Unix Epoch)"
            type="number"
            hint="Enter Unix timestamp in milliseconds"
            persistent-hint
          ></v-text-field>
          <v-text-field
            :value="readableDate"
            label="Readable Date"
            readonly
          ></v-text-field>
          <v-row class="mt-4">
            <v-col cols="12" sm="6" md="4" lg="2" v-for="(btn, index) in clearForButtons" :key="index">
              <v-btn @click="clearForDate(btn.timestamp)" color="primary" block>
                {{ btn.label }}
              </v-btn>
            </v-col>
          </v-row>

          <v-card outlined class="mt-4 mb-4">
            <v-card-title>Store Selection</v-card-title>
            <v-card-text>
              <v-autocomplete
                v-model="packageInfo.stores"
                :items="availableStores"
                item-text="name"
                item-value="_id"
                label="Select Stores"
                multiple
                chips
                :loading="loadingStores"
                :search-input.sync="storeSearch"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No stores found. Try a different search.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text caption"
                  >(+{{ packageInfo.stores.length - 1 }} others)</span>
                </template>
              </v-autocomplete>
            </v-card-text>
          </v-card>

          <v-row class="mt-6">
            <v-col cols="12" sm="6" class="d-flex">
              <v-btn type="submit" color="success" class="flex-grow-1">Submit</v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex">
              <v-btn @click="goBack" color="error" class="flex-grow-1">Cancel</v-btn>
            </v-col>
          </v-row>

        </v-form>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
import defaultIcon from '@/assets/default_app_icon.png';
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    quillEditor
  },
  data: () => ({
    metadata: {
      localized: {
        'en-US': {
          phoneScreenshots: []
        }
      },
      categories: [],
      license: "Unknown",
      lastUpdated: Date.now(),
      icon: "",
      name: "",
      packageName: "",
      authorName: "",
      authorEmail: "",
      webSite: "",
      description: "",
      summary: ""
    },
    packageInfo: {
      stores: [],
      splits: [],
      nativecode: [],
      allowedDespiteLimits: 0,
      hash: "",
      hashType: "sha256",
      packageName: "",
      versionCode: "",
      versionName: "",
      minSdkVersion: "",
      targetSdkVersion: "",
      sig: "",
      apkName: "",
      size: 0,
      added: Date.now()
    },
    iconSrc: defaultIcon,
    state: 'add',
    categories: [
      'Banking', 'Brain Teasers and STEM', 'Business', 'Cloud Storage', 'Communication',
      'Connectivity', 'Creativity', 'Email', 'Fitness', 'Games', 'Jewish', 'Mindfulness',
      'Money', 'Music', 'Navigation', 'News', 'Printing', 'Productivity', 'Reference',
      'School & Learning', 'Shopping', 'Sports', 'Torah', 'Travel', 'Weather'
    ],
    skipDate: false,
    systemApp: false,
    rules: {
      required: value => !!value || 'Required.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      },
    },
    uploading: false,
    uploadProgress: 0,
    uploadResult: null,
    clearForButtons: [
      { label: 'Method 3', timestamp: 1559174400000 },
      { label: 'Jan 2021', timestamp: 1609459200000 },
      { label: '64bit', timestamp: 1612137600000 },
      { label: '2024', timestamp: 1704067200000 },
      { label: 'Now', timestamp: () => Date.now() }
    ],
    editorOption: {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'direction': 'rtl' }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'align': [] }],
          ['clean'],
          ['link']
        ]
      }
    },
    baseApkProcessed: false,
    availableABIs: ['armeabi-v7a', 'arm64-v8a', 'x86', 'x86_64'],
    availableStores: [],
    loadingStores: false,
    storeSearch: '',
    storeErrorMessages: [],
    snackbar: false,
    snackbarText: '',
    snackbarColor: 'success'
  }),

  created() {
    this.fetchAppData();
    this.fetchStores();
  },
  watch: {
    '$route.params.id': 'fetchAppData'
  },

  computed: {
    readableDate() {
      return this.packageInfo.added
        ? new Date(this.packageInfo.added).toLocaleString()
        : 'No date set';
    }
  },
  methods: {
    async uploadApk(files) {
      if (!files || files.length === 0) {
        this.uploadResult = { type: 'warning', message: 'No files selected' };
        return;
      }

      this.uploading = true;
      this.uploadProgress = 0;
      this.uploadResult = null;

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('apkFiles', files[i]);
      }

      try {
        const response = await fetch('https://api.safetelecom.net/api/apps/upload/apk', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = '';
        let isReading = true;

        const processText = (text) => {
          const lines = text.split('\n');
          lines.forEach(line => {
            if (line.trim() !== '') {
              try {
                const data = JSON.parse(line);
                if (data.progress) {
                  this.uploadProgress = parseFloat(data.progress);
                }
              } catch (e) {
                console.error('Error parsing JSON:', e);
              }
            }
          });
        };

        while (isReading) {
          const { done, value } = await reader.read();

          if (done) {
            isReading = false;
            break;
          }

          buffer += decoder.decode(value, { stream: true });

          const lines = buffer.split('\n');
          buffer = lines.pop();
          processText(lines.join('\n'));
        }

        if (buffer) {
          processText(buffer);
        }

        // Final processing of the response
        const finalResponse = JSON.parse(buffer);
        if (finalResponse.apk) {
          this.updateComponentState(finalResponse.apk);
          this.uploadResult = { type: 'success', message: 'APKs processed successfully!' };
          this.baseApkProcessed = true;
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (error) {
        this.uploadResult = { type: 'error', message: `Error processing APKs: ${error.message}` };
        this.baseApkProcessed = false;
      } finally {
        this.uploading = false;
      }
    },

    async uploadSplits(files) {
      if (!files || files.length === 0) return;

      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append('apkFile', files[i]);

        try {
          const response = await fetch('https://api.safetelecom.net/api/apps/upload/apk', {
            method: 'POST',
            body: formData,
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();

          if (data.apk && data.apk.package) {
            if (!this.packageInfo.splits) {
              this.$set(this.packageInfo, 'splits', []);
            }
            this.packageInfo.splits.push(data.apk.package);
          }
        } catch (error) {
          console.error('Error uploading split APK:', error);
        }
      }
    },

    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
      this.packageData.splits.splice(index, 1);
    },

    updateComponentState(apkData) {
      this.metadata = { ...this.metadata, ...apkData.metadata };
      this.packageInfo = { ...this.packageInfo, ...apkData.package };
      this.metadata.packageName = this.packageInfo.packageName;
      this.metadata.icon = `${this.packageInfo.packageName}_icon.png`;
    },

    deleteSplit(index) {
      this.packageInfo.splits.splice(index, 1);
    },

    async populateInfo() {
      if (!this.baseApkProcessed || !this.packageInfo.packageName) {
        return;
      }

      try {
        const response = await fetch(`https://api.safetelecom.net/utils/playsearch/lookup?id=${this.packageInfo.packageName}`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        const descriptionText = this.unEscape(data.descriptionHTML);
        const summaryText = this.unEscape(data.summary);
        const icon = data.icon;
        this.iconSrc = 'https://' + icon.substr(icon.indexOf('//') + 2);

        this.metadata.name = data.title;
        this.metadata.authorName = data.developer;
        this.metadata.authorEmail = data.developerEmail;
        this.metadata.webSite = data.developerWebsite;
        this.metadata.summary = summaryText;
        this.metadata.description = descriptionText;
        this.metadata.icon = `${this.packageInfo.packageName}_icon.png`;
        this.$set(this.metadata, 'localized', this.metadata.localized || {});
        this.$set(this.metadata.localized, 'en-US', this.metadata.localized['en-US'] || {});
        this.$set(this.metadata.localized['en-US'], 'phoneScreenshots', []);
      } catch (error) {
        console.error('Error populating info:', error);
      }
    },

    clearForDate(timestamp) {
      if (typeof timestamp === 'function') {
        this.packageInfo.added = timestamp();
      } else {
        this.packageInfo.added = timestamp;
      }
    },

    async fetchStores() {
      this.loadingStores = true;
      try {
        const response = await fetch('https://api.safetelecom.net/api/stores?access=phones');
        if (!response.ok) throw new Error('Failed to fetch stores');
        this.availableStores = await response.json();
      } catch (error) {
        console.error('Error fetching stores:', error);
        this.showSnackbar('Error loading stores. Please try again.', 'error');
      } finally {
        this.loadingStores = false;
      }
    },

    async fetchAppData() {
      const id = this.$route.params.id;
      if (id && id !== 'new') {
        try {
          const response = await fetch(`https://api.safetelecom.net/api/apps/${id}`);
          if (!response.ok) throw new Error('Network response was not ok');
          const data = await response.json();
          this.metadata = { ...this.metadata, ...data.metadata };
          this.packageInfo = { ...this.packageInfo, ...data.package };
          this.skipDate = data.skipDate;
          this.systemApp = data.systemApp;
          this.iconSrc = `https://storage.googleapis.com/kosheros-store/icons/${data.metadata.icon}`;
          this.state = 'edit';
          this.packageInfo.stores = data.stores;
        } catch (error) {
          console.error('Error fetching app:', error);
          this.showSnackbar('Error loading app data. Please try again.', 'error');
        }
      }
    },

    async save() {
      if (!this.iconSrc.startsWith('http://') && !this.iconSrc.startsWith('https://')) {
        this.showSnackbar('Invalid icon URL. Must start with http:// or https://', 'error');
        return;
      }

      const payload = {
        metadata: {
          ...this.metadata,
          lastUpdated: Date.now()
        },
        package: this.packageInfo,
        skipDate: this.skipDate,
        systemApp: this.systemApp,
        publishDate: new Date().toISOString().split('T')[0],
        iconSrc: this.iconSrc
      };

      try {
        let response;
        if (this.state === 'add') {
          response = await fetch('https://api.safetelecom.net/api/apps', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
          });
        } else {
          const id = this.$route.params.id;
          response = await fetch(`https://api.safetelecom.net/api/apps/${id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
          });
        }

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
        }

        const savedApp = await response.json();

        // Update stores relationship
        await fetch(`https://api.safetelecom.net/api/apps/${savedApp._id}/stores`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ stores: this.packageInfo.stores })
        });

        this.showSnackbar('App saved successfully', 'success');
        this.$router.push({ path: '/apps' });
      } catch (error) {
        console.error('Error saving app:', error);
        this.showSnackbar(`Error saving app: ${error.message}`, 'error');
      }
    },

    showSnackbar(text, color = 'success') {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },

    goBack() {
      this.$router.go(-1);
    },

    unEscape(htmlStr) {
      htmlStr = htmlStr.replace(/&lt;/g , "<");
      htmlStr = htmlStr.replace(/&gt;/g , ">");
      htmlStr = htmlStr.replace(/&quot;/g , "\"");
      htmlStr = htmlStr.replace(/&amp;/g , "&");
      return htmlStr;
    }
  }
}
</script>

<style>
.quill-editor-container {
  height: 400px;
}

.quill-editor-container .quill-editor {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.quill-editor-container .ql-container {
  font-size: 16px;
  flex-grow: 1;
  overflow-y: auto;
}

.quill-editor-container .ql-toolbar {
  background: #f3f3f3;
}

@media (max-width: 600px) {
  .quill-editor-container .ql-toolbar {
    overflow-x: auto;
    white-space: nowrap;
  }
}
</style>
