<template>
	<div>
		<v-toolbar dark color="secondary">
			<v-btn rounded dark text class="mr-2" :to="{ name: 'Clients' }"><v-icon>arrow_back</v-icon></v-btn>
			<v-toolbar-title class="white--text">{{ currentClient.name }}</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon rounded dark @click="refreshPage">
				<v-icon>refresh</v-icon>
			</v-btn>
			<v-btn dark text @click.native="deleteItem(currentClient._id)"><v-icon>delete</v-icon></v-btn>
			<v-btn dark text @click.native="saveItem(currentClient._id)"><v-icon>save</v-icon></v-btn>
		</v-toolbar>
		<v-container fluid>
			<v-tabs
				fixed-tabs
				background-color="primary"
				dark
				show-arrows
			>
			<v-tabs-slider></v-tabs-slider>
				<v-tab>Client Info</v-tab>
				<v-tab-item> 
					<v-form
						ref="form"
						lazy-validation
					>
					<v-card>
						<v-card-text>
							<v-layout wrap>
								<v-flex xs6>
									<v-text-field outlined v-model="firstName" label="First Name" ref="firstName" class="pr-md-2" v-on:blur="updateName()" v-on:keyup.enter="focusOnInputField('lastName')" :rules="[v => !!v || 'First Name is required']"></v-text-field>
								</v-flex>
								<v-flex xs6>
									<v-text-field outlined v-model="lastName" label="Last Name" ref="lastName" class="pr-md-2" v-on:blur="updateName()" v-on:keyup.enter="focusOnInputField('email')" :rules="[v => !!v || 'Last Name is required']"></v-text-field>
								</v-flex>
								<v-flex xs12>
									<v-text-field outlined v-model="currentClient.email" label="Email" ref="email" v-on:blur="emailToLowerCase()" v-on:keyup.enter="focusOnInputField('accountNumber')" ></v-text-field>
								</v-flex>
								<v-flex xs12>
									<v-text-field outlined v-model="currentClient.serialNo" label="Account Number" ref="accountNumber" v-on:keyup.enter="focusOnInputField('imei')"></v-text-field>
								</v-flex>
								<v-flex xs12>
									<v-text-field outlined v-model="currentClient.imei" label="IMEI" ref="imei" v-on:keyup.enter="focusOnInputField('accountNotes')"></v-text-field>
								</v-flex>
								<v-flex xs12>

								<div v-for="(email, index) in currentClient.altEmails" :key="index">
									<v-text-field outlined v-model="currentClient.altEmails[index]" :label="`Alt Email ${index + 1}`" ref="altEmails" prepend-icon="mdi-plus" append-icon="mdi-minus" @click:prepend="addAltEmail()" @click:append="removeAltEmail(index)"></v-text-field>
								</div>
								</v-flex>
								<v-flex xs12 md6>
									<v-select
										v-model="currentClient.store"
										:items="stores"
										item-text="name"
										item-value="_id"
										label="Store"
										class="pr-md-2"
										outlined
										persistent-hint
										hint = "Warning: Any change in Store will result in a change to blocked activites."
										v-on:change="changeBlockedActivitiesToMatchStoreProfile()"
									></v-select>
								</v-flex>
								<v-flex xs12 md6>
									<v-select
										v-model="currentClient.retailer"
										:items="retailers"
										item-text="name"
										item-value="_id"
										label="Retailer"
										class="pl-md-2"
										outlined
									></v-select>
								</v-flex>
								<v-flex xs12 md6>
									<v-select
										v-model="currentClient.auditor"
										:items="auditors"
										item-text="name"
										item-value="_id"
										label="Auditor"
										class="pl-md-2"
										outlined
									></v-select>
								</v-flex>
								<v-flex xs12 md6>
									<v-autocomplete
										v-model="currentClient.excludedApps"
										:items="apps"
										item-text="metadata.name"
										item-value="metadata.packageName"
										label="Excluded Apps"
										class="pl-md-2"
										outlined
										clearable
										chips
										deletable-chips
										multiple
									></v-autocomplete>
								</v-flex>
								<v-flex xs12 md6>
									<v-autocomplete
										v-model="currentClient.addedApps"
										:items="apps"
										item-text="metadata.name"
										item-value="metadata.packageName"
										label="Added Apps"
										class="pl-md-2"
										outlined
										clearable
										chips
										deletable-chips
										multiple
									></v-autocomplete>
								</v-flex>
								<v-flex xs12>
									<v-textarea outlined v-model="currentClient.accountNotes" label="Account Notes"  ref="accountNotes"></v-textarea>
								</v-flex>
								<v-row justify="space-around">
									<v-flex xs12 md3>
										<v-switch label="Include Email" v-model="currentClient.emailPackage"></v-switch>
									</v-flex>
									<v-flex xs12 md3>
										<v-switch label="Include WhatsApp" v-model="currentClient.whatsApp"></v-switch>
									</v-flex>
									<v-flex xs12 md3>
										<v-switch label="Incude Android Auto" v-model="currentClient.autoEntitlement"></v-switch>
									</v-flex>
									<v-flex xs12 md3>
										<v-switch label="Include Sports" v-model="currentClient.sportsPackage"></v-switch>
									</v-flex>
									<v-flex xs12 md3>
										<v-switch label="Beta Tester" v-model="currentClient.betaTester"></v-switch>
									</v-flex>
									<v-flex xs12 md3>
										<v-switch label="Active Account" v-model="currentClient.isActive"></v-switch>
									</v-flex>
									<v-flex xs12 md3>
										<v-switch label="ADB Entitlement" v-model="currentClient.adbEntitlement"></v-switch>
									</v-flex>
									<v-flex xs12 md3>
										<v-switch label="Finance Ban" v-model="currentClient.financeBan"></v-switch>
									</v-flex>
									<v-flex xs12 md3>
										<v-switch label="Allow Google Maps" v-model="currentClient.mapsAllowed"></v-switch>
									</v-flex>
									<v-flex xs12 md3>
										<v-switch label="Allow Hotspot" v-model="currentClient.allowHotspot"></v-switch>
									</v-flex>
									<v-flex xs12 md3>
										<v-switch label="Disable Video Playback" v-model="currentClient.disallowVideoPlayback"></v-switch>
									</v-flex>
								</v-row>
							</v-layout>
						</v-card-text>
					</v-card>
					</v-form>
				</v-tab-item>
				<v-tab>Device Status</v-tab>
				<v-tab-item>
					<v-card>
						<v-list two-line subheader>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Device Added</v-list-item-title>
									<v-list-item-subtitle>{{ moment(currentClient.lastUpdated).format('MMMM DD, YYYY h:mm a') }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Device Age</v-list-item-title>
									<v-list-item-subtitle>{{ moment.preciseDiff(Date.now(), currentClient.lastUpdated) }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Phone Number</v-list-item-title>
									<v-list-item-subtitle>{{ currentClient.phoneNumber ? new PhoneNumber( currentClient.phoneNumber.toString(), 'US' ).toJSON()['number']['national'] : 'unset' }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Device (codename)</v-list-item-title>
									<v-list-item-subtitle>{{ deviceLookup ? deviceLookup.name : 'unknown' }} ({{ currentClient.device }})</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Serial</v-list-item-title>
									<v-list-item-subtitle>{{ currentClient.serialNo }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Account Hash</v-list-item-title>
									<v-list-item-subtitle>{{ currentClient.accountHash }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>IMEI</v-list-item-title>
									<v-list-item-subtitle>{{ currentClient.imei }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Baseband</v-list-item-title>
									<v-list-item-subtitle>{{ currentClient.baseband }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Carrier (SIM)</v-list-item-title>
									<v-list-item-subtitle>{{ currentClient.carrier }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item v-if="carrierLookup">
								<v-list-item-content>
									<v-list-item-title>Carrier (Network)</v-list-item-title>
									<v-list-item-subtitle>{{ carrierLookup.network }} ({{ carrierLookup.country }})</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>MCC / MNC</v-list-item-title>
									<v-list-item-subtitle>{{ currentClient.mcc }} / {{ currentClient.mnc }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>KosherOS Version</v-list-item-title>
									<v-list-item-subtitle>{{ currentClient.latestBuildDate ? moment(currentClient.latestBuildDate).format('LL') : '' }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Last Check-In</v-list-item-title>
									<v-list-item-subtitle>{{ moment(currentClient.lastCheckIn).calendar(null, { sameElse: 'M-D-YYYY h:mm:ss a' }) }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card>
				</v-tab-item>
				<v-tab>FCM Data Message</v-tab>
				<v-tab-item>
					<v-card>
					<v-card-title>Send FCM Data Message</v-card-title>
					<v-card-text>
						<v-select
						v-model="selectedFcmToken"
						:items="fcmTokenItems"
						label="Select FCM Token"
						item-text="label"
						item-value="token"
						outlined
						></v-select>
						<v-select
						v-model="selectedProjectId"
						:items="projectIds"
						label="Select Project ID"
						outlined
						></v-select>
						<v-text-field
						v-model="dataKey"
						label="Data Key"
						outlined
						></v-text-field>
						<v-text-field
						v-model="dataValue"
						label="Data Value"
						outlined
						></v-text-field>
						<v-btn color="primary" @click="sendFcmMessage" :disabled="!selectedProjectId || !selectedFcmToken || !dataKey || !dataValue">Send FCM Data Message</v-btn>
					</v-card-text>
					</v-card>
					<v-card class="mt-4" v-if="fcmResult">
					<v-card-title>FCM Message Result</v-card-title>
					<v-card-text>
						<pre>{{ JSON.stringify(fcmResult, null, 2) }}</pre>
					</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab>Installed Apps</v-tab>
				<v-tab-item>
					<v-card>
						<v-list style="column-count: 2;">
							<v-list-item v-for="app in currentClient.appNames" :key="app.id">
								<v-list-item-content>
									<v-list-item-title>{{ app }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card>
				</v-tab-item>
		<v-tab>Feature Codes</v-tab>
		<v-tab-item>
			<v-card>
				<v-list style="column-count: 2;">
					<v-list-item v-for="(item, index) in currentClient.featureList" :key="item">
						<v-list-item-content>
							<v-switch :label="index" v-model="currentClient.featureList[index]"></v-switch>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-card>
		</v-tab-item>
        <v-tab>Blocked Activities</v-tab>
        <v-tab-item>
            <v-container>
				<v-card>
					<v-card-title>
						Activity Groups
					</v-card-title>
					<v-card-actions class="pa-md-4">
						<v-btn dark color="primary" @click.native="importActivitiesFromStoreProfile">Import from Store Profile</v-btn>
					</v-card-actions>
					<v-divider ></v-divider>
						<v-card elevation="0" max-width="1000" class="pa-md-4">
						<v-expansion-panels inset v-model="panel" >
							<v-expansion-panel>
								<v-expansion-panel-header>
									<v-chip-group column>
										<v-chip
											disabled
											color="white"
										>
											<strong>Activity Groups</strong>
										</v-chip>
										<v-chip
											v-for="(selection, index) in selectedActivityGroups"
											:key="index"
											:disabled="loading"
											class="shrink"
										>
											{{ selection}}
										</v-chip>
									</v-chip-group>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<v-layout wrap>
										<v-flex xs12>
											<v-row dense>
												<v-col
													v-for="(group, index) in this.activityGroups"
													:key="index"
													cols="12"
													dense
												>
													<v-checkbox
						v-model="selectedActivityGroups"
													:label="group.name"
													:value="group.name"
													></v-checkbox>
												</v-col>
											</v-row>
										</v-flex>
									</v-layout>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-card>
					<v-card-actions class="pa-md-4">
						<v-btn dark color="success" @click.native="blockActivitesFromSelectedGroups">Apply</v-btn>
						<v-btn dark color="error" @click.native="clearSelectedActivityGroups">Clear</v-btn>
					</v-card-actions>
				</v-card>
			</v-container>
			<v-container>
			<v-card>
				<v-card-title>Blocked Activities <v-btn dark x-large text color="error" @click.native="clearBlockedActivites">Clear</v-btn></v-card-title>
				<v-container>				
              <v-row dense>
                <v-col
                  v-for="(activity, index) in this.activities"
                  :key="index"
                  cols="12"
                  dense
                >
                  <v-checkbox
                    v-model="currentClient.activityBlocklist"
                    :label="activity.name"
                    :value="activity._id"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
			</v-container>
        </v-tab-item>
			</v-tabs>
		</v-container>
	</div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import * as momentDiff from 'moment-precise-range-plugin'
import PhoneNumber from 'awesome-phonenumber'

export default {
	name: "ClientDetail",
	data () {
		return {
			tab: null,
			mobileBreakpoint: 0,
			moment: moment,
			stores: [],
			apps: [],
			retailers: [],
			auditors: [],
			carrierList: [],
			devices: [],
			activities: [],
			activityGroups: [],
			selectedActivityGroups: [],
			panel: false,
			firstName: '',
			lastName: '',	
			currentClient: {
				name: '',
				email: '',
				serialNo: '',
				imei: '',
				emailPackage: false,
				isActive: true,
				betaTester: false,
				whatsApp: false,
				sportsPackage: false,
				activityBlocklist: [],
				retailer: "5a9b713b960b423014504813",
				auditor: "610357773db92000416eff99",
				store: "5a0d9be20823dc23dfcacc18",
				featureList: {},
				altEmails: [],
			},
			selectedFcmToken: null,
			selectedProjectId: null,
			dataKey: '',
			dataValue: '',
			fcmResult: null,
			projectIds: []
		}
	},
	props: ['retailer', 'auditor'],
    computed: {
		carrierLookup () {
			var foundCarrier = this.carrierList.find(carrier => {
				return carrier.mcc == this.currentClient.mcc && carrier.mnc == this.currentClient.mnc
			})
			return foundCarrier
		},
		deviceLookup () {
			var device = this.devices.find(item => {
				return item.codename == this.currentClient.device
			})
			return device
		},
		PhoneNumber () {
			return PhoneNumber
		},
		MomentDiff () {
			return momentDiff
		},
		selectedStore () {
			return this.currentClient.store
		},
		clientName () {
			return this.firstName + ' ' + this.lastName;
		},
		fcmTokenItems() {
			return this.currentClient.fcmTokens ? this.currentClient.fcmTokens.map(token => ({
				label: `${token.app} - ${token.token.substr(0, 20)}...`,
				token: token.token
			})) : []
		}
	},
	methods: {
		updateName () {
			this.firstName = this.firstName.trim();
			this.lastName = this.lastName.trim();
			this.firstName = this.toTitleCase(this.firstName);
			this.lastName = this.toTitleCase(this.lastName);
			this.toTitleCase(this.lastName);
			this.currentClient.name = this.clientName;

		},
		toTitleCase(string) {
			const regex = /\w\S*/g;
			return string.replace( regex, word => {
				return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
				}
			);
		},
		emailToLowerCase() {
			this.currentClient.email = this.currentClient.email.trim().toLowerCase();
		},
		getClient () {
			if (this.$attrs.id != "new") {
				axios.get(`https://api.safetelecom.net/api/clients/${this.$attrs.id}`)
				.then(response => {
					this.currentClient = response.data
					var nameComponents = this.currentClient.name.split(" ");
					for (var i = 0; i < nameComponents.length - 1; i++) {
						this.firstName = this.firstName + " " + nameComponents[i];
					}
					this.lastName = nameComponents[nameComponents.length - 1];
				})
			}
		},
		async getData () {
			this.apps = (await (await fetch(`https://api.safetelecom.net/api/apps?access=${this.retailer.accessLevel}`)).json())
			this.stores = await (await fetch(`https://api.safetelecom.net/api/stores?access=${this.retailer.accessLevel}`)).json()
			this.devices = await (await fetch(`https://api.safetelecom.net/api/devices`)).json()
			this.retailers = (await (await fetch(`https://api.safetelecom.net/api/retailers`)).json()).sort((a, b) => {
				if(a.name < b.name) { return -1 }
				if(a.name > b.name) { return 1 }
				return 0
			})
			this.auditors = (await (await fetch(`https://api.safetelecom.net/api/auditors`)).json()).sort((a, b) => {
				if(a.name < b.name) { return -1 }
				if(a.name > b.name) { return 1 }
				return 0
			})
		},
		getCarriers () {
			axios.get(`https://raw.githubusercontent.com/elisam98/mcc-mnc-table/master/mcc-mnc-table.json`)
			.then(response => {
				this.carrierList = response.data
			})
		},
		getActivities() {
			axios.get(`https://api.safetelecom.net/api/activities`).then((response) => {
				this.activities = response.data.sort((a, b) => {
					if(a.name < b.name) { return -1 }
					if(a.name > b.name) { return 1 }
					return 0
				});

				// Remove Default Activities
				axios.get(`https://api.safetelecom.net/api/activitygroups/60dde9659aefe3003abab569`).then(response => {
					var defaultBlockedActivities = response.data.activities;
					this.activities.forEach(activity => {
						if (defaultBlockedActivities.includes(activity._id)) {
							var index = this.activities.indexOf(activity);
							if (index > -1) {
								this.activities.splice(index, 1);
							}
						}
					})
				})
			})
		},
		getActivityGroups() {
			axios.get(`https://api.safetelecom.net/api/activitygroups`).then((response) => {
				response.data.forEach(item => {
					if(item.name != "Default BlockList") {
						this.activityGroups.push({
							name: item.name,
							id: item._id,
							activities: item.activities
						})
					}
				})
				this.activityGroups = this.activityGroups.sort((a, b) => {
					if(a.name < b.name) { return -1 }
					if(a.name > b.name) { return 1 }
					return 0
						});
					})
		},
		importActivitiesFromStoreProfile() {
			var storeActivityGroups = [];
			var store = (typeof this.selectedStore._id !== 'undefined') ? this.selectedStore._id : this.selectedStore;
			return axios.get(`https://api.safetelecom.net/api/stores/${store}`)
			.then((response) => {
				var activityGroupBlocklist = response.data.activityGroupBlocklist;
				this.activityGroups.forEach((group) => {
					if (activityGroupBlocklist.includes(group.id) ) {
						storeActivityGroups.push(group.name)
					}
				});
				var groupsToBlock = this.activityGroups.filter( group => storeActivityGroups.includes(group.name))
			groupsToBlock.forEach(group => {
				group.activities.forEach(activity => this.currentClient.activityBlocklist.push(activity));
			});
			this.currentClient.activityBlocklist = Array.from(new Set(this.currentClient.activityBlocklist));
			})
		},
		changeBlockedActivitiesToMatchStoreProfile() {
			this.clearBlockedActivites();
			this.clearSelectedActivityGroups();
			var store = (typeof this.selectedStore._id !== 'undefined') ? this.selectedStore._id : this.selectedStore;
			axios.get(`https://api.safetelecom.net/api/stores/${store}`)
			.then((response) => {
				var activityGroupBlocklist = response.data.activityGroupBlocklist;
				this.activityGroups.forEach((group) => {
					if (activityGroupBlocklist.includes(group.id) ) {
						this.selectedActivityGroups.push(group.name)
					}
				});
				this.selectedActivityGroups = Array.from( new Set(this.selectedActivityGroups) );
			this.blockActivitesFromSelectedGroups();	
			})
				
		},
		blockActivitesFromSelectedGroups () {
			var groupsToBlock = this.activityGroups.filter( group => this.selectedActivityGroups.includes(group.name))
			groupsToBlock.forEach(group => {
				group.activities.forEach(activity => this.currentClient.activityBlocklist.push(activity));
			});
			this.currentClient.activityBlocklist = Array.from(new Set(this.currentClient.activityBlocklist));
			this.selectedActivityGroups = [];
			this.panel = false;
		},
		clearBlockedActivites () {
			this.currentClient.activityBlocklist = [];
		},
		clearSelectedActivityGroups () {
			this.selectedActivityGroups = [];
			this.panel = false;
		},
		deleteItem (id) {
			confirm('Are you sure you want to delete this item?') && axios.delete(`https://api.safetelecom.net/api/clients/${id}`).then(() => {
				this.$router.push({ name: 'Clients' })
			})
		},
		saveItem () {
			if (this.$refs.form.validate()) {
			if (this.$attrs.id != "new") {
				axios.put(`https://api.safetelecom.net/api/clients/${this.currentClient._id}`, this.currentClient).then(() => {
					this.$router.push({ name: 'Clients' })
				})
			} else {
				axios.post(`https://api.safetelecom.net/api/clients`, this.currentClient).then(() => {
					this.$router.push({ name: 'Clients' })
				})
				}
			}
		},
		focusOnInputField(inputField) {
			this.$refs[inputField].focus();
		},
		refreshPage () {
			this.currentClient = {}
			this.getData()
			this.getClient()
			this.selectedActivityGroups = [];
		},
		addAltEmail () {
			console.log("clicked")
			this.currentClient.altEmails.push("")
		},
		removeAltEmail (index) {
			this.currentClient.altEmails.splice(index, 1)
		},
		async sendFcmMessage() {
			if (!this.selectedFcmToken || !this.selectedProjectId || !this.dataKey || this.dataValue === undefined) {
				alert('Please select an FCM token, a Project ID, and enter both a Data Key and Value');
				return;
			}

			try {
				const response = await axios.post('https://api.safetelecom.net/messaging/send-message', {
					token: this.selectedFcmToken,
					projectId: this.selectedProjectId,
					dataKey: this.dataKey,
					dataValue: this.dataValue
				});
				this.fcmResult = response.data;
			} catch (error) {
				console.error('Error sending FCM message:', error);
				this.fcmResult = { success: false, error: error.response?.data?.error || error.message };
			}
		},
		async getProjectIds() {
			try {
				const response = await axios.get('https://api.safetelecom.net/messaging/service-accounts');
				this.projectIds = response.data;
			} catch (error) {
				console.error('Error fetching project IDs:', error);
				this.projectIds = [];
			}
		},
	},
	created () {
		this.getData()
		this.getClient()
		this.getCarriers()
		this.getActivities();
		this.getActivityGroups();
		this.getProjectIds();
	}
}
</script>
