<template>
  <div>
    <v-app-bar dark color="secondary">
      <v-toolbar-title class="white--text">Updates</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn round @click="refreshPage">
        <v-icon>refresh</v-icon>
      </v-btn>
      <v-btn rounded icon dark to="/updates/new">
        <v-icon>add</v-icon>
      </v-btn>
    </v-app-bar>
    <v-container>
      <v-card>
        <v-data-table
          dense
          :loading="loading"
          :headers="headers"
          :items="items"
          :sort-by="['lastUpdated']"
          :sort-desc="[false]"
          :items-per-page="-1"
          item-key="_id"
          must-sort
          class="elevation-1"
        >
          <template v-slot:[`item.published`]="{ item }"
            ><v-icon>{{
              item.published ? "check_box" : "check_box_outline_blank"
            }}</v-icon></template
          >
          <template v-slot:[`item.datetime`]="{ item }">{{
            moment(item.datetime * 1000).calendar(null, {
              sameElse: "MM/DD/YYYY",
            })
          }}</template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn small rounded icon class="mr-2" :to="'updates/' + item._id">
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn
              small
              rounded
              icon
              class="mr-2"
              @click="deleteItem(item._id)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "UpdateList",
  data() {
    return {
      loading: true,
      moment: moment,
      headers: [
        { text: "Date", value: "datetime" },
        { text: "Device", value: "device" },
        { text: "Timestamp", value: "incrementalTimestamp" },
        { text: "Version", value: "version" },
        { text: "Rom Type", value: "romtype" },
        { text: "Published", value: "published" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
    };
  },
  methods: {
    getItems() {
      this.loading = true;
      axios.get(`https://api.safetelecom.net/api/updates`).then((response) => {
        this.items = response.data;
        this.loading = false;
      });
    },
    deleteItem(id) {
      confirm("Are you sure you want to delete this item?") &&
        axios
          .delete(`https://api.safetelecom.net/api/updates/${id}`)
          .then(() => {
            this.refreshPage();
          });
    },
    refreshPage() {
      this.items = [];
      this.getItems();
    },
  },
  created() {
    this.getItems();
  },
};
</script>
