<template>
	<v-container fluid>
		<v-row>
			<v-toolbar dark color="secondary">
				<v-toolbar-title class="white--text">Orders</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn icon rounded dark @click="refreshPage">
					<v-icon>refresh</v-icon>
				</v-btn>
			</v-toolbar>
		</v-row>
		<v-row>
		<v-col md="4">
		<v-list two-line>
			<v-list-item-group v-model="selectedOrder" active-class="blue--text">
				<v-list-item v-for="order in orders" :key="order._id">
					<v-list-item-icon>
						<v-icon v-if="!order.processed" color="grey lighten-1">mdi-star-outline</v-icon>
						<v-icon v-else color="yellow darken-3">mdi-star</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>#{{ order.orderNumber }} - {{ order.name }}</v-list-item-title>
						<v-list-item-subtitle class="text--primary">{{ order.email }} &bullet; {{ new PhoneNumber( order.phoneNumber.toString(), 'US' ).toJSON()['number']['national'] }}</v-list-item-subtitle>
						<v-list-item-subtitle v-for="(lineItem, index) in order.lineItems" :key="index">&bullet; {{ lineItem }}</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action>
						<v-list-item-action-text>{{ moment(order.orderDate).format('L') }}</v-list-item-action-text>
					</v-list-item-action>
				</v-list-item>
			</v-list-item-group>
		</v-list>
		</v-col>
		<v-col md="8">
        <v-card>
            <v-card-title>
                Client Info
            </v-card-title>
            <v-form ref="form">
                <v-card-text>
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-text-field outlined v-model="currentOrder.name" label="Name" required
                                :rules="[() => !!this.currentOrder.name || 'This field is required']"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field outlined v-model="currentOrder.email" label="Email"></v-text-field>
                        </v-flex>
						<v-flex xs12>
							<v-text-field outlined v-model="currentOrder.serialNo" label="Account Number" ref="accountNumber" v-on:keyup.enter="focusOnInputField('imei')"></v-text-field>
						</v-flex>
						<v-flex xs12>
							<v-text-field outlined v-model="currentOrder.imei" label="IMEI" ref="imei"></v-text-field>
						</v-flex>
                        <v-flex xs12 md6>
                            <v-select v-model="currentOrder.store" :items="stores" item-text="name" item-value="_id" label="Store"
                                outlined required></v-select>
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-select v-model="currentOrder.retailer" :items="retailers" item-text="name" item-value="_id"
                                label="Retailer" outlined required></v-select>
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-select v-model="currentOrder.auditor" :items="auditors" item-text="name" item-value="_id" label="Auditor"
                                outlined required></v-select>
                        </v-flex>
                        <v-row justify="space-around">
                            <v-flex xs12 md2>
                                <v-switch label="Active Account" v-model="currentOrder.isActive"></v-switch>
                            </v-flex>
                            <v-flex xs12 md2>
                                <v-switch label="Include Email" v-model="currentOrder.emailPackage"></v-switch>
                            </v-flex>
                            <v-flex xs12 md2>
                                <v-switch label="Include WhatsApp" v-model="currentOrder.whatsApp"></v-switch>
                            </v-flex>
                            <v-flex xs12 md2>
                                <v-switch label="Beta Tester" v-model="currentOrder.betaTester"></v-switch>
                            </v-flex>
                        </v-row>
                    </v-layout>
                </v-card-text>
            </v-form>
            <v-card-actions>
				<v-spacer></v-spacer>
                <v-btn @click="refreshPage()">Cancel</v-btn>
                <v-btn @click="submitForm">Submit</v-btn>
            </v-card-actions>
        </v-card>
		</v-col>
		</v-row>
	</v-container>
</template>
<script>
import moment from 'moment'
import PhoneNumber from 'awesome-phonenumber'

export default {
	name: "OrdersRoute",
	data () {
		return {
			selectedOrder: 0,
			mobileBreakpoint: 0,
			moment: moment,
			orders: [],
			stores: [],
			retailers: [],
			auditors: [],
		}
	},
    computed: {
		PhoneNumber () {
			return PhoneNumber
		},
		currentOrder () {
			let customer = this.orders[this.selectedOrder]
			return {
				name: customer.name,
				email: customer.email,
				serialNo: '',
				imei: '',
				emailPackage: false,
				isActive: true,
				betaTester: false,
				whatsApp: false,
				sportsPackage: false,
				activityBlocklist: [],
				retailer: "5af7bd22246f50235ca7698d",
				auditor: "",
				store: "5a0d9be20823dc23dfcacc18",
				featureList: {}
			}

		}
	},
	methods: {
		async getData () {
			this.orders = (await (await fetch(`https://api.safetelecom.net/api/orders`)).json()).sort((a, b) => {
				return Date.parse(a.orderDate) - Date.parse(b.orderDate)
			})
			this.stores = await (await fetch(`https://api.safetelecom.net/api/stores?access=phones`)).json()
			this.retailers = (await (await fetch(`https://api.safetelecom.net/api/retailers`)).json()).sort((a, b) => {
				if(a.name < b.name) { return -1 }
				if(a.name > b.name) { return 1 }
				return 0
			})
			this.auditors = (await (await fetch(`https://api.safetelecom.net/api/auditors`)).json()).sort((a, b) => {
				if(a.name < b.name) { return -1 }
				if(a.name > b.name) { return 1 }
				return 0
			})
		},
		async submitForm () {
			this.currentOrder.processed = true

			const response = await fetch(`https://api.safetelecom.net/api/clients`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(this.currentOrder)
			})
			if(!response.ok) {
				alert('There has been an error with your request. Please try again')
			} else {
				this.refreshPage()
			}
		},
		refreshPage () {
			this.orders = []
			this.getData()
		},
	},
	created () {
		this.getData()
	}
}
</script>
